/* eslint-disable @next/next/no-img-element */
import React from "react";
import Link from "next/link";

const Everyone = () => {
  return (
    <div className=" bg-home-everyone-background-image bg-cover relative">
      <div className=" h-3 md:h-1 absolute top-0 right-0 left-0 w-full bg-black"></div>
      <div className=" max-w-7xl w-full mx-auto py-24 px-4 pb-24  text-white">
        <div className="flex justify-center flex-col gap-4 items-center">
          <h3 className="uppercase text-2xl font-bold text-center leading-9 text-[#f8f5f0] tracking-[9.5px]">
            BEYOND ENTERTAINMENT
          </h3>
          <h2 className=" text-[#ffa535] text-4xl leading-[54px] text-center font-black max-w-md">
            We’re Active Wherever Victims Need a Voice
          </h2>
          <div className=" grid grid-cols-1 lg:grid-cols-4 mt-10 gap-4 px-12 lg:px-0">
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a
              className="flex flex-col gap-4 hover:transform hover:scale-[1.025] transition-transform ease-in-out duration-300 cursor-pointer"
              href="/about"
            >
              <>
              <img
                decoding="async"
                src="https://imagedelivery.net/uavUKOC7HZo0CRGpx2We4A/c19b92d9-0286-4e1f-2cae-13888910df00/public"
                alt="Uncovered logo and string"
              />
              <h3 className="text-2xl font-semibold">About Us</h3>
              <p className=" text-base">
                We’re creating a platform to ensure that there is equity for
                BIPOC, LGBTQ+, and other underserved victims not prioritized in
                the true crime community.
              </p>
              <span className="text-base font-semibold text-red">
                Learn More{" "}
              </span>
              </>
            </a>
            <Link href="/cases" passHref>
              <a className="flex flex-col gap-4 hover:transform hover:scale-[1.025] transition-transform ease-in-out duration-300 cursor-pointer">
                <img
                  decoding="async"
                  src="https://imagedelivery.net/uavUKOC7HZo0CRGpx2We4A/0a6c2e37-e5ce-4162-b8ea-1b8a2dc52000/public"
                  alt="Screenshot of part of a map with case indicators"
                />
                <h3 className="text-2xl font-semibold">Cold Cases Near You</h3>
                <p className=" text-base">
                  We know from research, having a geographic connection to an
                  unsolved case inspires action. Check out and contribute to the
                  map of cold cases.
                </p>
                <span className="text-base font-semibold text-red">
                  See the Case Map
                </span>
              </a>
            </Link>
            <a
              className="flex flex-col gap-4 hover:transform hover:scale-[1.025] transition-transform ease-in-out duration-300 cursor-pointer"
              href="https://community.uncovered.com/"
            >
              <img
                decoding="async"
                src="https://imagedelivery.net/uavUKOC7HZo0CRGpx2We4A/53841012-3309-47fa-9749-e498af938b00/public"
                alt="Screenshot of the community page on a laptop"
              />
              <h3 className="text-2xl font-semibold">Our Community</h3>
              <p className=" text-base">
                Join our community for advocates, citizen detectives and true
                crime enthusiasts. Use your research skills and learn from
                others as you work on cases together.
              </p>
              <span className="text-base font-semibold text-red">
                Join the Community
              </span>
            </a>
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a
              className="flex flex-col gap-4 hover:transform hover:scale-[1.025] transition-transform ease-in-out duration-300 cursor-pointer"
              href="/resources"
            >
              <>
              <img
                decoding="async"
                src="https://imagedelivery.net/uavUKOC7HZo0CRGpx2We4A/a8af007b-c53a-4de0-ed87-ff0ae168ec00/public"
                alt="Photo of a notepad, laptop, and headphones"
              />
              <h3 className="text-2xl font-semibold">Resources</h3>
              <p className=" text-base">
                We believe that through collective impact, we can uncover
                information that may lead to a big break in a cold case through
                thoughtful Citizen Detective work.
              </p>
              <span className="text-base font-semibold text-red">
                Get Resources
              </span>
              </>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Everyone;
