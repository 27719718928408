/* eslint-disable @next/next/no-img-element */
import React from "react";
import Link from "next/link";

const Help = () => {
  return (
    <div className=" max-w-7xl w-full mx-auto pt-16 px-4 pb-24">
      <h3 className="uppercase text-2xl font-bold text-center leading-9 text-gray-580 tracking-[9.5px]">
        CALLING ALL CITIZEN DETECTIVES
      </h3>
      <div className="flex justify-center mt-4 flex-col ">
      <div className="text-center mx-auto max-w-xl  ">
          <h4 className="yellow-highlight  text-center !leading-[45px]  font-bold !text-4xl ">
            Why Do We Need Your Help?
          </h4>
        </div>  
        <div className="flex mt-9 justify-center">
          <Link href="/join">
            <a className="bg-red-600 flex-shrink-0 text-lg font-bold justify-center  text-center hover:bg-red-700 leading-[1.166] duration-300 py-3 px-14  ml-3 flex items-center mt-2 text-white transition  rounded-full">
              Download the Citizen
              <br />
              Detective Guide
            </a>
          </Link>
        </div>
      </div>
      <div className=" grid grid-cols-1 lg:grid-cols-3 mt-20 gap-6">
        <div className=" flex flex-col gap-6">
          <img
            decoding="async"
            src="https://imagedelivery.net/uavUKOC7HZo0CRGpx2We4A/c9982026-32a1-495a-811a-b049e27e0800/public"
            alt="Photo of police line tape"
          />

          <h3 className=" text-2xl font-extrabold">Limited Resources</h3>
          <p className=" text-base text-gray-580">
            Law enforcement is constrained by policies, budgets, community
            dynamics, and antiquated technology. With only 10% of agencies
            having dedicated cold case detectives, the decision to work on new
            investigations versus old ones is often made for them.
          </p>
        </div>
        <div className=" flex flex-col gap-6">
          <img
            decoding="async"
            src="https://imagedelivery.net/uavUKOC7HZo0CRGpx2We4A/ab1292f2-e60e-4776-25be-c5590d700100/public"
            alt="Photo of a microphone and blurred crowd"
          />

          <h3 className=" text-2xl font-extrabold">Limited Voice</h3>
          <p className=" text-base text-gray-580">
            Cases go cold and victims’ families spend years frozen in the moment
            while continuing to look for answers. Often, this forces them to
            become their own advocate and learn how to fight a system that seems
            to be working against them.
          </p>
        </div>
        <div className=" flex flex-col gap-6">
          <img
            decoding="async"
            src="https://imagedelivery.net/uavUKOC7HZo0CRGpx2We4A/016c6b05-1249-4e68-0c31-203b23bc3e00/public"
            alt="Photo of a person watching a streaming service and eating popcorn"
          />

          <h3 className=" text-2xl font-extrabold">Limited Compassion</h3>
          <p className=" text-base text-gray-580">
            Society’s obsession with true crime isn’t new, but when the content
            diminishes the very people whose stories are told while lionizing
            the perpetrators in the process, a line is crossed. When the goal is
            entertainment, it can be hard to remember just how real the victim
            is.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Help;
